import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  description?: string
  subtitle?: string
  title?: string
}

export const Paragraph = memo(function Paragraph({
  description,
  subtitle,
  title,
}: Props) {
  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      {subtitle ? (
        <FadeInUp>
          <Subtitle>{subtitle}</Subtitle>
        </FadeInUp>
      ) : null}
      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  max-width: 46.25rem;
  margin: 12.5rem auto;

  @media (max-width: 1023px) {
    max-width: none;
    margin: 7.5rem auto;
    padding: 0 1.875rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 4.25rem;
  margin-bottom: 1.125rem;

  @media (max-width: 1023px) {
    font-size: 2.5rem;
    line-height: 3.375rem;
  }
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.6875rem;
  padding-left: 3.75rem;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 2.5rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
    top: 0.75rem;
    left: 0;
  }

  @media (max-width: 767px) {
    padding-left: 2.5rem;
    &:before {
      content: '';
      width: 1.5rem;
    }
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
  margin-top: 2.5rem;

  p {
    margin-block-end: 1em;
    &:last-of-type {
      margin-block-end: 0;
    }
  }

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 1.75rem;
  }
`
